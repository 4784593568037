import React from 'react';
import { Helmet } from "react-helmet";

import LogoImg from '../images/logo-appicon-192.png';
import HeroImg from '../images/hero.jpg';
import Footer from '../elements/footer';

export function Index() {
  return (
    <div className="page">
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <title>Mitgliederportal.org</title>
        <meta name="description" content="Simplify your organisations workflows, reducing administrative burden and increasing knowledge management. Ideal for Junior Enterprises, NGOs and small businesses." />
      </Helmet>
      <div className="relative bg-ui-1 overflow-hidden">
        <div className="max-w-7xl mx-auto">
          <div className="relative z-10 pb-8 bg-ui-1 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg
              className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-text-3 transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>

            <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
              <nav
                className="relative flex items-center justify-between sm:h-10 lg:justify-start"
                aria-label="Global"
              >
                <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                  <div className="flex items-center justify-between w-full md:w-auto">
                    <a href="/">
                      <span className="sr-only">mitgliederportal.org</span>
                      <img className="h-8 w-auto sm:h-10" src={LogoImg} />
                    </a>
                    <div className="hidden -mr-2 flex items-center md:hidden">
                      <button
                        type="button"
                        className="bg-ui-1 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
                        aria-expanded="false"
                      >
                        <span className="sr-only">Open main menu</span>
                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M4 6h16M4 12h16M4 18h16"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
                  {/*<a href="#" className="font-medium text-gray-500 hover:text-gray-900">Product</a>*/}
                  {/*<a href="#" className="font-medium text-gray-500 hover:text-gray-900">Features</a>*/}
                  {/*<a href="#" className="font-medium text-gray-500 hover:text-gray-900">Marketplace</a>*/}
                  {/*<a href="#" className="font-medium text-gray-500 hover:text-gray-900">Company</a>*/}
                  <a
                    href="https://app.mitgliederportal.org"
                    className="font-medium text-primary-500 hover:text-primary-500"
                  >
                    Sign in
                  </a>
                </div>
              </nav>
            </div>

            <div className="hidden absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
              <div className="rounded-lg shadow-md bg-ui-1 ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="px-5 pt-4 flex items-center justify-between">
                  <div>
                    <img
                      className="h-8 w-auto"
                      src={LogoImg}
                      alt="Logo (Compass)"
                    />
                  </div>
                  <div className="-mr-2">
                    <button
                      type="button"
                      className="bg-ui-1 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
                    >
                      <span className="sr-only">Close main menu</span>
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                {/*                <div className="px-2 pt-2 pb-3 space-y-1">
                  <a href="#" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">Product</a>
                  <a href="#" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">Features</a>
                  <a href="#" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">Marketplace</a>
                 <a href="#" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">Company</a>
                </div>*/}
                <a
                  href="https://app.mitgliederportal.org"
                  className="block w-full px-5 py-3 text-center font-medium text-primary-500 bg-gray-50 hover:bg-gray-100"
                >
                  Sign in
                </a>
              </div>
            </div>

            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                  <span className="block xl:inline">
                    Bringing your organisation
                  </span>
                  <span className="hidden lg:inline">&nbsp;</span>
                  <span className="block sm:mt-2 text-primary-500 xl:inline">
                    forward
                  </span>
                </h1>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  Simplify your organisations workflows, reducing administrative
                  burden and increasing knowledge management.
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md shadow">
                    <a
                      href="mailto:support@mitgliederportal.org"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-text-3 bg-primary-500 hover:bg-primary-600 md:py-4 md:text-lg md:px-10"
                    >
                      Contact us for early access
                    </a>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
            src={HeroImg}
            alt=""
          />
        </div>
      </div>
      <div className="py-12 bg-ui-1">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <h2 className="text-base text-primary-500 font-semibold tracking-wide uppercase">
              Highlights
            </h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Focus on your business
            </p>
            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
              Easy, flexible and integrated solution to solve routine workflows
              to put your organisation in the right place for its future
              development.
            </p>
          </div>

          <div className="mt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              <div className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-text-3">
                    <svg className="fill-current" width="24" height="24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM224 32c52.94 0 96 43.06 96 96c0 52.93-43.06 96-96 96S128 180.9 128 128C128 75.06 171.1 32 224 32zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304zM413.3 480H34.66C33.2 480 32 478.8 32 477.3C32 399.4 95.4 336 173.3 336h101.3C352.6 336 416 399.4 416 477.3C416 478.8 414.8 480 413.3 480z"/></svg>
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                    Business administration
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  Manage your organisation and how people move around in your
                  organisations. Keep track of the positions, teams and
                  attendance of your members.
                </dd>
              </div>

              <div className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-text-3">
                    <svg className="fill-current" width="24" height="24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 0c-123.7 0-224 35.82-224 80v352c0 44.18 100.3 80 224 80s224-35.82 224-80v-352C448 35.82 347.7 0 224 0zM224 32c123 0 188.1 35.2 192 48l.0156 80.08C364.9 180.2 296.8 192 224 192C151.2 192 83.1 180.2 31.95 160.1L31.94 80.57C35.91 67.2 100.1 32 224 32zM416 193.5l.0234 126.6C364.9 340.2 296.8 352 224 352c-72.81 0-140.9-11.84-192-31.93L31.96 193.4C85.68 212.5 152.7 224 223.1 224C295.3 224 362.3 212.5 416 193.5zM224 480c-123 0-188.1-35.2-192-48l-.0137-78.55C85.7 372.5 152.7 384 223.1 384c71.31 0 138.3-11.48 192.1-30.56l.0137 77.98C412.1 444.8 347 480 224 480z"/></svg>
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                    Integrated knowledge management
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  Let knowledge management become part of your every-day
                  routine, collecting data right were it is produced.
                </dd>
              </div>

              <div className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-text-3">
                    <svg className="fill-current" width="24" height="24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M488.6 256.7L388 219V107.9c0-15-9.3-28.4-23.4-33.7l-96-36c-8.1-3.1-17.1-3.1-25.3 0l-96 36c-14.1 5.3-23.4 18.7-23.4 33.7V219L23.4 256.7C9.3 262 0 275.4 0 290.4v101.3c0 13.6 7.7 26.1 19.9 32.2l96 48c10.1 5.1 22.1 5.1 32.2 0L256 418l107.9 54c10.1 5.1 22.1 5.1 32.2 0l96-48c12.2-6.1 19.9-18.6 19.9-32.2V290.4c0-15-9.3-28.4-23.4-33.7zM16.5 403.8V295.1l107.2 46.5v115.8zm231 0l-107.2 53.6V341.6l107.2-46.5zm0-126.7l-115.5 50-115.5-50v-.2L131 234l107.6 39.6 8.9 3.3zm.3-19.7L195 239.6l-54.5-20.4V112.5L247.8 159zM140.5 94.5v-.1L256 51l115.5 43.3v.2l-115.5 50zM264.2 159l107.2-46.5v106.7L317 239.6l-52.8 17.8zm107.6 298.4l-107.2-53.6V295.1l107.2 46.5zm123.7-53.6l-107.2 53.6V341.6l107.2-46.5zm0-126.7l-115.5 50L264.5 277v-.2l8.9-3.3L381 234l114.5 42.9z"/>
                    </svg>
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                    Flexible datastructures and permissions
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  Manage yourself which data you keep track of and how your
                  organisation is structured as well as who can access what.
                </dd>
              </div>

              <div className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-text-3">
                    <svg className="fill-current" width="24" height="24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M8 128h432c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8H8c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8zm496 112H72c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h432c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8zm-64 144H8c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h432c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8z"/></svg>
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                    Process automation
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  Regular administrative workflows, like requesting data and
                  updating members profiles can be automated reducing overhead
                  freeing up more time for taking care of your organisations
                  development.
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">Ready to move forward?</span>
            <span className="block text-primary-500">
              Start to explore our platform today.
            </span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">
              <a
                href="mailto:hello@mitgliederportal.org"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-text-3 bg-primary-500 hover:bg-primary-600"
              >
                Contact us for exclusive access
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="py-12 bg-ui-1">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <h2 className="text-base text-primary-500 font-semibold tracking-wide uppercase">
              All Modules
            </h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              One platform - Different modules
            </p>
            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
              Modules can be used independently, but integrate with each other
              allowing for exciting combinations.
            </p>
          </div>

          <div className="mt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              <div className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-text-3">
                    <svg className="fill-current" width="24" height="24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM224 32c52.94 0 96 43.06 96 96c0 52.93-43.06 96-96 96S128 180.9 128 128C128 75.06 171.1 32 224 32zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304zM413.3 480H34.66C33.2 480 32 478.8 32 477.3C32 399.4 95.4 336 173.3 336h101.3C352.6 336 416 399.4 416 477.3C416 478.8 414.8 480 413.3 480z"/></svg>
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                    Organisation
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  The base of our tool is your organisation. Keep track of the involvement of your team members and their journey through your organisation.
                </dd>
              </div>

              <div className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-text-3">
                    <svg className="fill-current" width="24" height="24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                      <path d="M112 432h32c17.7 0 32-14.4 32-32v-32c0-17.6-14.3-32-32-32h-32c-17.7 0-32 14.4-32 32v32c0 17.6 14.3 32 32 32zm0-64h32v32h-32v-32zm0-192h32c17.7 0 32-14.4 32-32v-32c0-17.6-14.3-32-32-32h-32c-17.7 0-32 14.4-32 32v32c0 17.6 14.3 32 32 32zm0-64h32v32h-32v-32zM416 0H32C14.3 0 0 14.4 0 32v448c0 17.6 14.3 32 32 32h384c17.7 0 32-14.4 32-32V32c0-17.6-14.3-32-32-32zm0 480H32V32h384v448zM216 144h128c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H216c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8zm0 128h128c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H216c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8zm0 128h128c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H216c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8zm-97.4-113.6c2.1 2.1 5.5 2.1 7.6 0l64.2-63.6c2.1-2.1 2.1-5.5 0-7.6l-12.6-12.7c-2.1-2.1-5.5-2.1-7.6 0l-47.6 47.2-20.6-20.9c-2.1-2.1-5.5-2.1-7.6 0l-12.7 12.6c-2.1 2.1-2.1 5.5 0 7.6l36.9 37.4z"/></svg>
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                    Polls
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  Create polls or forms to collect data, works perfectly with the Workflow module to update profiles or create a Ticketing system with the Task module.
                </dd>
              </div>

              <div className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-text-3">
                    <svg className="fill-current" width="24" height="24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 0c-123.7 0-224 35.82-224 80v352c0 44.18 100.3 80 224 80s224-35.82 224-80v-352C448 35.82 347.7 0 224 0zM224 32c123 0 188.1 35.2 192 48l.0156 80.08C364.9 180.2 296.8 192 224 192C151.2 192 83.1 180.2 31.95 160.1L31.94 80.57C35.91 67.2 100.1 32 224 32zM416 193.5l.0234 126.6C364.9 340.2 296.8 352 224 352c-72.81 0-140.9-11.84-192-31.93L31.96 193.4C85.68 212.5 152.7 224 223.1 224C295.3 224 362.3 212.5 416 193.5zM224 480c-123 0-188.1-35.2-192-48l-.0137-78.55C85.7 372.5 152.7 384 223.1 384c71.31 0 138.3-11.48 192.1-30.56l.0137 77.98C412.1 444.8 347 480 224 480z"/></svg>
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                    Datastore
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  Store collected data and make it available to your teams in a structured way. Each collection has fixed column types, allowing for a higher data quality.
                </dd>
              </div>

              <div className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-text-3">
                    <svg className="fill-current" width="24" height="24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M8 128h432c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8H8c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8zm496 112H72c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h432c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8zm-64 144H8c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h432c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8z"/></svg>
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                    Workflows & Automation
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  Use our workflow automation capabilities to integrate the other modules and simplify standard processes and free up more time for your important tasks.
                </dd>
              </div>

              <div className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-text-3">
                    <svg className="fill-current" width="24" height="24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M145.35 207a8 8 0 0 0-11.35 0l-71 71-39-39a8 8 0 0 0-11.31 0L1.35 250.34a8 8 0 0 0 0 11.32l56 56a8 8 0 0 0 11.31 0l88-88a8 8 0 0 0 0-11.32zM62.93 384c-17.67 0-32.4 14.33-32.4 32s14.73 32 32.4 32a32 32 0 0 0 0-64zm82.42-337A8 8 0 0 0 134 47l-71 71-39-39a8 8 0 0 0-11.31 0L1.35 90.34a8 8 0 0 0 0 11.32l56 56a8 8 0 0 0 11.31 0l88-88a8 8 0 0 0 0-11.32zM503 400H199a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h304a8 8 0 0 0 8-8v-16a8 8 0 0 0-8-8zm0-320H199a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h304a8 8 0 0 0 8-8V88a8 8 0 0 0-8-8zm0 160H199a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h304a8 8 0 0 0 8-8v-16a8 8 0 0 0-8-8z" /></svg>
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                    Tasks
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  Experimental: Manage your workflows in one platform and use tasks to coordinate your work. This module also allows you to keep track of decisions, essential for good knowledge management.
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Index;
