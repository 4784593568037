import React from 'react';

export function Footer() {
  return (
    <footer className="bg-gray-50 pt-16 pb-12 sm:pt-20 md:pt-24 xl:pt-32 sm:pb-20">
        <div className="max-w-screen-lg xl:max-w-screen-xl mx-auto divide-y divide-gray-200 px-4 sm:px-6 md:px-8">
          <ul className="text-sm font-medium pb-14 sm:pb-20 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-10">
            <li className="space-y-5 row-span-2">
              <h2 className="text-xs font-semibold tracking-wide text-gray-900 uppercase">
                More information coming!
              </h2>
              <ul className="space-y-4">
                <li>
                  <a
                    className="hover:text-gray-900 transition-colors duration-200"
                    href="/legal"
                  >
                    Impressum – Legal Notice
                  </a>
                </li>
              </ul>
            </li>
            <li className="space-y-5 row-span-2">
              <h2 className="text-xs font-semibold tracking-wide text-gray-900 uppercase">
                Contact
              </h2>
              <ul className="space-y-4">
                <li>
                  <a
                    className="hover:text-gray-900 transition-colors duration-200"
                    href="mailto:hello@mitgliederportal.org"
                  >
                    hello@mitgliederportal.org
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </footer>
  );
}

export default Footer;
